<!-- 个体需要修改样式 -->
<template>
  <div id="containerId" class="container" v-if="myDataObj">
    <!-- 封面 -->
    <img v-if="myDataObj && myDataObj.cover" :src="myDataObj.cover" class="coverImg" />

    <!-- 基本信息容器 -->
    <PersonalContainer :myDataObj="myDataObj" :activityName="myDataObj.activityName" :evaluator="myDataObj.evaluator"
      :timer="myDataObj.timer" :reportType="1" :isHiddenBtn="isHiddenBtn" :isExport="isExport" />

    <!-- 工具介绍(项目) -->
    <div class="dimensionResultContainer" v-if="myDataObj.introduction">
      <TitleBar :title="myDataObj.introduction.title" />
      <ContentBox :content="myDataObj.introduction.content" :isBackGroundColor="true" />
    </div>

    <!-- 测评详情 -->
    <div v-for="(paper, index) in myDataObj.papers" :key="index">
      <el-divider class="dividerONE">
        <h1>{{ paper.name }}</h1>
      </el-divider>
      <!-- 工具介绍(量表) -->
      <div class="dimensionResultContainer" v-if="paper">
        <TitleBar :title="paper.introduction.title" />
        <ContentBox :content="paper.introduction.content" :isBackGroundColor="true" />
        <img v-if="paper && paper.introduction" :src="paper.introduction.imageUrl" class="coverImg" />
      </div>
      <!-- 总体测评结果(量表) -->
      <div class="reportContainer" v-if="paper.totalResult">
        <TitleBar :title="paper.totalResult.name" style="margin-top: 0; width: auto" />
        <div class="reportCenterDiv">
          <div class="circleDiv">
            <img :src="wsHeartImg" class="circleHeartImg" />
            <div class="circleScoreDiv">
              {{ paper.totalResult.score || 0 }}分
            </div>
          </div>
          <div class="rectDiv">
            <div class="unitRectDiv" v-for="(item, index1) in unitRectDivList" :key="index1">
              <div class="deepUnitRectDiv" :style="{ width: item.deepWidth + 'px' }"></div>
            </div>
          </div>
        </div>
        <div v-if="paper.totalResult && myDataObj.papers.length > 0" class="reportRightDiv"
          :style="{ backgroundColor: paper.totalResult.color }">
          {{ paper.totalResult.conclusion }}
        </div>
      </div>
      <!-- 结果解释 -->
      <div class="totalResultContainer" v-if="paper.totalResult">
        <TitleBar title="结果解释" />
        <ContentBox :content="paper.totalResult.myExplain" :isBackGroundColor="true" />
      </div>
      <!-- 建议 -->
      <div class="totalResultContainer" v-if="paper.totalResult">
        <TitleBar title="建议" />
        <ContentBox :content="paper.totalResult.advise" :isBackGroundColor="true" />
      </div>

      <!-- 维度测评结果 -->
      <!-- 维度测评结果--- 整体柱状图 -->
      <div class="dimensionChartResultContainer" v-if="paper.detailResult">
        <TitleBar :title="paper.detailResult.chart.title" v-if="paper.detailResult.chart" />
        <div class="dimensionChartView" v-if="paper.detailResult.chart">
          <!-- bar条形图  pie饼状图 line折线图 radar雷达图-->
          <!-- <div id="myChart" ref="myChart" class="barChart"></div> -->
          <div :id="paper.detailResult.chart.chartId" class="barChart"></div>
        </div>
      </div>

      <!-- 维度测评结果--- 维度结果列表 -->
      <div class="dimensionResultContainer" v-if="paper.detailResult.scales">
        <TitleBar title="结果建议" />
        <div class="dimensionResultItem" v-for="(item, index2) in paper.detailResult.scales" :key="index2">
          <div class="dimensionTopDiv">
            <div v-if="!isExport" class="dimensionTopSegmentDiv"></div>
            <!-- 维度名称、得分、等级 -->
            <div class="dimensionTopContentDiv">
              {{ item.name || "" }}/{{ item.score }}/
              <span>{{ item.conclusion || "" }}</span>
            </div>
          </div>
          <!-- 维度说明/介绍 -->
          <ContentBox v-if="item.overview && item.overview.length > 0" :content="item.overview"
            :isBackGroundColor="true" />
          <!-- 结果解释  -->
          <ContentBox v-if="item.myExplain && item.myExplain.length > 0 && !item.myExplain.match(/^[ ]+$/)"
            :content="item.myExplain" :imgSrc="wsListResultImg" :isBackGroundColor="true" />
          <!-- 建议  -->
          <ContentBox v-if="item.advise && item.advise.length > 0 && !item.advise.match(/^[ ]+$/)"
            :content="item.advise" :imgSrc="wsListSuggestImg" :isBackGroundColor="true" />
        </div>
      </div>
    </div>

    <!-- 总体建议 -->
    <div class="dimensionResultContainer" v-if="myDataObj.advice.title != ''">
      <TitleBar :title="myDataObj.advice.title" />
      <ContentBox :content="myDataObj.advice.content" :isBackGroundColor="true" />
    </div>

    <!-- 封底 -->
    <img v-if="myDataObj && myDataObj.backCover" :src="myDataObj.backCover" class="coverImg" />
  </div>
</template>
<script>
import htmlToPdf from "@/utils/htmlToPdf";
import PersonalContainer from "@/components/personalContainer";
import { mixins } from "@/utils/mixins";
import { getPersonalReport } from "@/api/report";

export default {
  mixins: [mixins],
  name: "PersonalReport",
  components: {
    PersonalContainer
  },
  data() {
    return {
      wsHeartImg: require("../../assets/images/caseReportNew/list_heart.png"), //心图片
      myDataObj: undefined, //个案网络请求回来的数据
      unitRectDivList: [
        {
          type: 0,
          ratio: 1,
          deepWidth: 0,
        },
        {
          type: 1,
          ratio: 1,
          deepWidth: 0,
        },
        {
          type: 2,
          ratio: 0.5,
          deepWidth: 0,
        },
        {
          type: 3,
          ratio: 0,
          deepWidth: 0,
        },
        {
          type: 4,
          ratio: 0,
          deepWidth: 0,
        },
        {
          type: 5,
          ratio: 0,
          deepWidth: 0,
        },
        {
          type: 6,
          ratio: 0,
          deepWidth: 0,
        },
        {
          type: 7,
          ratio: 0,
          deepWidth: 0,
        },
        {
          type: 8,
          ratio: 0,
          deepWidth: 0,
        },
        {
          type: 9,
          ratio: 0,
          deepWidth: 0,
        },
      ],
      jtData: {}
    };
  },
  methods: {
    //导出报告按钮点击事件
    ws_exportReportBtnClickAction() {
      //导出PDF
      // htmlToPdf.downloadPDF(document.querySelector("#containerId"), "我的PDF");
      htmlToPdf.printOut(document.querySelector("#containerId"));
    },
    //计算分数
    ws_getReportScoreActionWithScore(reportScore, reportTotalScore) {
      reportScore = reportScore || 0;
      reportTotalScore = reportTotalScore || 0.01;
      reportScore = (reportScore / reportTotalScore) * 100;

      //   var wsCount =  parseInt(reportScore /10);
      var wsCount = Math.floor(reportScore / 10);
      var wsRemainder = reportScore % 10;
      wsCount = wsRemainder > 0 ? wsCount + 1 : wsCount;
      var tempList = this.unitRectDivList;
      for (var i = 0; i < tempList.length; i++) {
        var tempDic = tempList[i];
        if (i <= wsCount - 1) {
          tempDic.ratio = 1;
          tempDic.deepWidth = 11;
          if (wsRemainder > 0 && i == wsCount - 1) {
            tempDic.ratio = wsRemainder / 10;
            tempDic.deepWidth = (11 * wsRemainder) / 10;
          }
        } else {
          tempDic.ratio = 0;
          tempDic.deepWidth = 0;
        }
      }
      this.unitRectDivList = tempList;
    },
    //渲染echarts事件
    ws_drawEchartsAction() {
      this.myDataObj.papers.forEach((item, index) => {
        var wsOption = item.detailResult.chart.option;
        let idStr = "chart" + index;
        let charts = this.$echarts.init(document.getElementById(idStr));
        charts.setOption(wsOption);
      });
    },
    //个案数据网络请求
    ws_geCaseReportDataNetWorkAction() {
      var that = this;
      getPersonalReport(that.queryParams).then((res) => {
        //成功
        if (res.code == 200) {
          that.myDataObj = res.data;

          // 遍历 that.myDataObj.papers 并为子项添加id属性
          that.myDataObj.papers.forEach((item, index) => {
            item.detailResult.chart.chartId = `chart${index}`;
          });

          //渲染echart图
          that.$nextTick(() => {
            that.ws_drawEchartsAction();
          });
        } else {
          // uni.showToast({
          // 	title: res.statusCode != 200 ? res.statusCode : res.data.msg,
          // 	icon: 'none'
          // });
        }
      });
    },
  },
  mounted() {
    //个案数据网络请求
    this.ws_geCaseReportDataNetWorkAction();
    //实现自适应部分
    window.onresize = () => {
      // 基于准备好的dom，初始化echarts实例
      // let myChart = this.$echarts.init(document.getElementById("myChart"));
      // let myChart = this.$echarts.init(this.$refs.myChart);
      // myChart.resize();
      this.myDataObj.papers.forEach((item, index) => {
        let idStr = "chart" + index;
        let charts = this.$echarts.init(document.getElementById(idStr));
        charts.resize()
      });
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~styles/commonPage.scss";

.dividerONE {
  width: 100%;
  height: 2px;
  background-color: #5656d4;
  margin-top: 60px;
}

.dividerONE h1 {
  font-size: 15px;
  //letter-spacing: 2px;
  color: $ws-mainColor;
  font-weight: bold;
  line-height: 45px;
  font-family: '宋体';
  white-space: nowrap;
}

.el-divider__text.is-center {
  background-color: #ebeff8;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
</style>
